@use "../styles/theme.scss";

:local(.home-page) {
  flex: 1;
  background-size: cover;
  padding: 0;
  flex-direction: column;
  align-items: center;

  @media (min-width: theme.$breakpoint-lg) {
    align-items: center;
    justify-content: flex-start;
  }

  & > section {
    margin: 2em auto;
  }
}

:local(.logo-container) {
  display: flex;
  justify-content: center;
  padding: 20px;
  width: 150px;
  
  img {
    width: 100%;
    align-self: flex-start;
  }

  @media (min-width: theme.$breakpoint-lg) {
    display: none;
  }
}

:local(.sign-in-container) {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  @media(min-width: theme.$breakpoint-lg) {
    display: none;
  }
}

:local(.mobile-sign-out) {
  padding: 0.5rem;
}

:local(.hero) {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (min-width: theme.$breakpoint-lg) {
    flex-direction: row;
  }
}

:local(.menu) {
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    color: black;
    background-color: theme.$lightgrey;
    padding: 20px;
    border-radius: 4px;
    margin: 20px;
  }
}
//$lightgrey-hover: #f5f5f5;
//$lightgrey-pressed: #dbdbdb;

:local(.explore-box) {
  outline: 3px solid black;
  padding: 40px;
  border-radius: 5px;
  min-width: 240px;

  h4 {
    font-size: theme.$font-size-xl;
    margin-bottom: 20px;
  }

  ul {
    list-style-type: disc;
    font-size: 16px;
  }
}

:local(.hero-image-container) {

  img {
    @media (min-width: theme.$breakpoint-lg) {
      border-radius: 16px;
    }
  }
}

:local(.app-info) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  & > * {
    margin-bottom: 20px;
  }
  
  @media (min-width: theme.$breakpoint-lg) {
    margin-bottom: 0;
    margin-left: 16px;
    justify-content: flex-start;
    align-items: flex-start;

    & > :last-child {
      margin-bottom: 0;
    }
  }
}

:local(.app-title) {
  white-space: pre-wrap;
  align-self: auto;
  font-size: theme.$font-size-2xl;
  font-weight: theme.$font-weight-bold;
  text-align: center;
  margin: 0 24px 20px 24px;
  line-height: 1.25;

  @media (min-width: theme.$breakpoint-lg) {
    text-align: left;
    margin: 0 16px 48px 0px;
    max-width: 860px;
    font-size: theme.$font-size-2xl;
  }
}

:local(.app-description) {
  white-space: pre-wrap;
  align-self: auto;
  font-size: theme.$font-size-lg;
  font-weight: theme.$font-weight-medium;
  text-align: center;
  margin: 0 24px 20px 24px;
  line-height: 1.25;

  @media (min-width: theme.$breakpoint-lg) {
    text-align: left;
    margin: 0 32px 48px 0px;
    max-width: 860px;
    font-size: theme.$font-size-xl;
  }
}

:local(.center-logo) {
  align-self: auto;
  margin: 24px 0;
}

:local(.cta-buttons) {
  display: flex;
  flex-direction: column;
  align-items: center;
}

:local(.features) {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  
  & > * {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    @media(min-width: theme.$breakpoint-lg) {
      margin-right: 24px;
      margin-bottom: 0;
      flex-direction: row;
      max-width: 364px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
  
  img {
    width: 100%;
    border-radius: 12px;
  }
}

:local(.subbutton-link) {
  color: black;
  margin-left: 20px;
  text-decoration: underline;
}

:local(.learning-container) {
  margin-top: 120px !important;
  display: flex;
  flex-direction: column;
}

:local(.learning-left) {
  background-color: theme.$blue;
  height: 100%;
  min-height: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px;
}

:local(.learning-middle) {
  background-color: theme.$lightgrey;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

:local(.learning-right){ 
  background-color: theme.$lightgrey;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content:flex-end;
}

:local(.rooms-container) {
  margin-top: 120px !important;
  display: block;
  flex-direction: column;
}

:local(.rooms-heading) {
  margin-left: 40px;
  font-size: 24px;
  margin-bottom: 4px;
}

:local(.rooms-description) {
  margin-left: 40px;
  font-size: 18px;
  margin-bottom: 12px;
}
 
:local(.rooms) {
//  background-color: theme.$background2-color;
  border-radius: 16px;
}

:local(.row) {
  display: flex;
  justify-content: space-evenly !important;
}

:local(.col-lg) {
  flex-direction: column;

  @media(min-width: theme.$breakpoint-lg) {
    flex-direction: row;
  }
}

:local(.home-page) :local(.card) {
  background-color: theme.$background2-color;
  border-radius: 16px;
  font-weight: theme.$font-weight-medium;
  padding-bottom: 32px !important;
  flex: 1;
}

:local(.center-lg) {
  @media(max-width: theme.$breakpoint-lg) {
    text-align: center;
    align-items: center;
  }
}
